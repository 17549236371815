import SCSS from '../SVG.module.scss';

export const Walls = () => {
	return (
		<g className={SCSS.Walls}>
			<polyline
				points='80.58,33.47 80.58,56.67 83.03,56.67 83.03,35.92 174.09,35.92 174.09,52.57 177.57,52.57 
	177.57,35.92 195.64,35.92 195.64,123.96 177.57,123.96 177.57,106.6 174.09,106.6 174.09,123.96 112.37,123.96 112.37,129.88 
	110.62,129.88 110.62,133.36 112.37,133.36 112.37,145.46 115.86,145.46 115.86,127.7 207.07,127.7 207.07,196.93 212.52,196.93 
	212.52,193.44 210.55,193.44 210.55,123.96 199.13,123.96 199.13,30.09 196.6,30.09 196.6,33.47 80.58,33.47'
			/>
			<polygon
				points='80.58,105.39 83.03,105.39 83.03,129.88 85.08,129.88 85.08,133.36 59.22,133.36 59.22,130.91 
	80.58,130.91'
			/>
			<polygon
				points='328.87,300.11 241.63,300.11 241.63,383.82 187.51,383.82 187.51,305.75 204.66,305.75 
	204.66,227.18 57.72,227.18 57.72,237.67 29.08,237.67 29.08,237.67 29.08,237.67 3.64,237.67 3.64,207.56 6.09,207.56 
	6.09,235.22 27.16,235.22 27.16,223.94 207.9,223.94 207.9,296.88 210.14,296.88 210.14,300.36 207.9,300.36 207.9,308.98 
	190.74,308.98 190.74,380.59 235.74,380.59 235.74,374.98 237.57,374.98 237.57,363.17 235.74,363.17 235.74,356.65 238.4,356.65 
	238.4,300.36 235.68,300.36 235.68,296.88 324.74,296.88 324.74,294.63 328.87,294.63'
			/>
			<polygon
				points='288.62,137.52 244.01,137.46 244.01,134.57 240.52,134.57 240.52,145.03 244.01,145.03 
	244.01,142.71 288.62,142.71 288.62,214.38 244.01,214.38 244.01,211.56 241.79,211.56 241.79,199.75 244.01,199.75 
	244.01,170.57 240.52,170.57 240.52,193.44 238.06,193.44 238.06,196.93 240.52,196.93 240.52,222.6 244.01,222.6 244.01,219.63 
	262.02,219.63 262.02,235.13 258.92,235.13 258.92,242.1 244.33,242.09 244.01,242.09 244.01,240.61 240.52,240.61 240.52,268.38 
	263.82,268.38 263.82,264.9 261.58,264.9 261.58,237.79 288.51,237.79 288.51,264.9 286.32,264.9 286.32,268.38 291.99,268.38 
	291.99,263.78 324.74,263.78 324.74,267.61 328.87,267.61 328.87,260.03 291.86,260.03 291.86,232.54 291.86,232.54 
	291.86,219.63 291.86,35.85 291.86,35.85 291.86,6.18 291.86,3.65 271.72,5.06 271.89,7.48 288.62,6.31 288.62,95.08 
	240.52,95.08 240.52,109.02 243.99,109.02 243.99,98.55 288.62,98.55'
			/>
			<circle cx='61.56' cy='44.27' r='11.22' />
		</g>
	);
};
export default Walls;

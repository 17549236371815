import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MasterBedroom: IScene = {
	name: 'Master Bedroom',
	pan: '-90deg',
	hotspot: {
		x: 210,
		y: 70,
	},
	images: {
		scheme1: Scheme1.MasterBedroom,
		scheme2: Scheme2.MasterBedroom,
	},
	default: {
		latitude: -0.021657523948410695,
		longitude: 1.5696655632740237,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.41063712582934575,
				longitude: 2.7756049660011826,
			},
			target: {
				name: 'Ensuite',
				view: {
					latitude: -0.035851581291137524,
					longitude: 3.13534025262311,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.1581959537483133,
				longitude: 3.0591205895109197,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: 0.024736794771074155,
					longitude: 3.143973453694857,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.26614335770544173,
				longitude: 3.0584974220236636,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.0036874772650268284,
					longitude: 3.759734410221448,
				},
			},
		},
	],
};

export default MasterBedroom;

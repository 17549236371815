import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Ensuite: IScene = {
	name: 'Ensuite',
	pan: '-90deg',
	hotspot: {
		x: 255,
		y: 170,
	},
	images: {
		scheme1: Scheme1.Ensuite,
		scheme2: Scheme2.Ensuite,
	},
	default: {
		latitude: -0.027554604920626335,
		longitude: 1.5756179526966312,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.5765263170283994,
				longitude: 5.71080855263234,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.021657523948410695,
					longitude: 1.5696655632740237,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -1.0442933728848676,
				longitude: 4.592015148354319,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.04167986366982568,
					longitude: 4.725368102636022,
				},
			},
		},
	],
};

export default Ensuite;

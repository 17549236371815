import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const MainBath: IScene = {
	name: 'Main Bath',
	pan: '90deg',
	hotspot: {
		x: 220,
		y: 330,
	},
	images: {
		scheme1: Scheme1.MainBath,
		scheme2: Scheme2.MainBath,
	},
	default: {
		latitude: -0.058435866022969574,
		longitude: 4.701653657771356,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.2520096817724107,
				longitude: 6.255714718652406,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.03447771253782017,
					longitude: 6.22057809987467,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.33062692087192946,
				longitude: 6.10011719877669,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: 0.026306512001917248,
					longitude: 6.282334479656275,
				},
			},
		},
	],
};

export default MainBath;

import SCSS from '../SVG.module.scss';

const Lineworks = () => {
	return (
		<g className={SCSS.Lineworks}>
			<rect x='0.27' y='236.92' width='3.38' height='0.75' />
			<rect x='0.27' y='208.31' width='3.38' height='28.62' />
			<rect x='0.27' y='206.81' width='3.38' height='1.5' />
			<rect x='0.27' y='186.16' width='3.38' height='20.65' />
			<rect x='0.27' y='184.66' width='3.37' height='1.5' />
			<rect x='0.27' y='159.14' width='3.38' height='25.52' />
			<rect x='0.27' y='157.64' width='3.38' height='1.5' />
			<rect x='0.27' y='132.41' width='3.38' height='25.23' />
			<rect x='0.27' y='130.91' width='3.38' height='1.5' />
			<rect x='0.27' y='127.54' width='6.75' height='3.38' />
			<rect x='32.65' y='127.54' width='0.75' height='3.38' />
			<rect x='33.4' y='127.54' width='25.07' height='3.38' />
			<rect x='58.47' y='127.54' width='1.5' height='3.38' />
			<rect x='59.97' y='127.54' width='16.49' height='3.38' />
			<rect x='76.46' y='127.54' width='0.75' height='3.38' />
			<rect x='77.21' y='127.54' width='3.38' height='3.38' />
			<rect x='77.21' y='126.79' width='3.38' height='0.75' />
			<rect x='77.21' y='106.14' width='3.38' height='20.64' />
			<rect x='77.21' y='104.64' width='3.38' height='1.5' />
			<rect x='77.21' y='79.57' width='3.38' height='25.07' />
			<rect x='77.21' y='78.07' width='3.38' height='1.5' />
			<rect x='77.21' y='57.42' width='3.38' height='20.65' />
			<rect x='77.21' y='55.92' width='3.38' height='1.5' />
			<rect x='77.21' y='34.22' width='3.38' height='21.7' />
			<rect x='81.33' y='30.09' width='22.56' height='3.38' />
			<rect x='103.9' y='30.09' width='1.5' height='3.38' />
			<rect x='105.4' y='30.09' width='28.03' height='3.38' />
			<rect x='133.42' y='30.09' width='1.5' height='3.38' />
			<rect x='134.92' y='30.09' width='28.03' height='3.38' />
			<rect x='162.95' y='30.09' width='1.5' height='3.38' />
			<rect x='164.45' y='30.09' width='28.03' height='3.38' />
			<rect x='192.48' y='30.09' width='0.75' height='3.38' />
			<rect x='193.23' y='29.34' width='3.38' height='0.75' />
			<rect x='193.23' y='30.09' width='3.38' height='3.38' />
			<rect x='193.23' y='11.06' width='3.38' height='18.28' />

			<rect
				x='197.2'
				y='6.01'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 -0.0263 14.6496)'
				width='25.07'
				height='3.38'
			/>

			<rect
				x='222.25'
				y='5.09'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 0.0707 15.5721)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='223.71'
				y='4.16'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 0.1676 16.4944)'
				width='25.07'
				height='3.38'
			/>

			<rect
				x='248.76'
				y='3.23'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 0.2645 17.4167)'
				width='1.5'
				height='3.38'
			/>

			<rect
				x='250.23'
				y='2.46'
				transform='matrix(0.9976 -0.0698 0.0698 0.9976 0.3453 18.1854)'
				width='20.65'
				height='3.38'
			/>
			<polygon points='291.86,3.65 291.85,0.27 272.23,1.64 272.46,5.01' />
			<polygon points='270.97,5.11 272.46,4.99 272.23,1.64 270.73,1.74' />
			<line x1='270.85' y1='3.43' x2='250.26' y2='4.87' />
			<line x1='248.76' y1='4.97' x2='223.75' y2='6.72' />
			<line x1='222.25' y1='6.83' x2='197.24' y2='8.58' />
			<line x1='194.92' y1='11.06' x2='194.92' y2='29.34' />
			<line x1='78.89' y1='57.42' x2='78.89' y2='78.07' />
			<line x1='78.89' y1='79.57' x2='78.89' y2='104.64' />
			<line x1='58.47' y1='129.22' x2='33.4' y2='129.22' />
			<line x1='1.95' y1='132.41' x2='1.95' y2='157.64' />
			<line x1='1.95' y1='159.14' x2='1.95' y2='184.66' />
			<line x1='1.95' y1='186.16' x2='1.95' y2='206.81' />
			<polygon points='0.25,126.06 1.38,126.06 1.38,24.94 76.13,15.76 76.13,28.6 77.26,28.6 77.26,14.49 0.25,23.94' />
			<rect x='175.56' y='52.57' width='1.48' height='27.77' />
			<rect x='174.09' y='66.45' width='1.48' height='27.77' />
			<rect x='190.74' y='356.59' width='45' height='24' />
			<path
				d='M212.17,378.21l-17.19-2.07c-1.13-0.14-1.98-1.1-1.98-2.23v-10.63c0-1.14,0.85-2.1,1.98-2.23l17.19-2.07
	c0.71-0.09,1.44-0.09,2.15,0l17.19,2.07c1.13,0.14,1.98,1.1,1.98,2.23v10.63c0,1.14-0.85,2.1-1.98,2.23l-17.19,2.07
	C213.6,378.29,212.88,378.29,212.17,378.21z'
			/>
			<rect x='85.08' y='104.37' width='1.33' height='25.51' />
			<rect x='212.52' y='167.93' width='1.33' height='25.51' />
			<rect x='244.01' y='145.03' width='25.51' height='1.33' />

			<rect
				x='239.81'
				y='124.41'
				transform='matrix(0.707 -0.7072 0.7072 0.707 -14.4589 215.252)'
				width='25.51'
				height='1.33'
			/>
			<rect x='234.35' y='300.36' width='1.33' height='25.51' />
			<line x1='258.92' y1='235.13' x2='253.02' y2='235.13' />
			<rect x='265.27' y='240.13' width='20.44' height='22.69' />
			<rect x='297.72' y='293.3' width='27.02' height='1.33' />
			<polyline points='196.53,356.59 196.53,332.59 207.62,332.59 207.62,309.02' />
			<polyline points='288.62,190.71 282.83,190.71 282.83,166.77 271.75,166.77 271.75,142.77' />
			<line x1='272.85' y1='146.64' x2='284.85' y2='146.64' />
			<line x1='282.09' y1='155.12' x2='282.09' y2='154.36' />
			<path d='M283.4,154.17c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='283.21' y1='155.12' x2='283.21' y2='154.36' />
			<path d='M283.21,155.12c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='282.28' y1='154.17' x2='286.5' y2='154.17' />
			<path d='M282.28,154.17c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='282.09' y1='155.12' x2='282.09' y2='154.36' />
			<path d='M282.09,155.12c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='286.5' y1='155.31' x2='282.28' y2='155.31' />
			<path d='M286.65,154.31c0-0.08-0.06-0.14-0.14-0.14' />
			<line x1='286.65' y1='155.17' x2='286.65' y2='154.31' />
			<path d='M286.5,155.31c0.08,0,0.14-0.06,0.14-0.14' />
			<line x1='284.85' y1='162.84' x2='284.85' y2='155.31' />
			<line x1='272.85' y1='162.84' x2='284.85' y2='162.84' />
			<line x1='272.85' y1='146.64' x2='272.85' y2='162.84' />
			<line x1='284.85' y1='154.17' x2='284.85' y2='146.64' />
			<path
				d='M274.12,172.59c-0.67,0-1.06,0.07-1.48,0.14c-0.54,0.09-1.12,0.2-1.65,0.4c-0.55,0.21-1.04,0.53-1.43,0.86
	c-0.3,0.25-0.55,0.5-0.73,0.73c-0.15,0.19-0.24,0.36-0.35,0.6c-0.1,0.23-0.2,0.51-0.28,0.8c-0.08,0.31-0.14,0.62-0.17,0.91
	c-0.03,0.3-0.03,0.57-0.03,0.91c0,0.32,0,0.7,0.03,1.04c0.03,0.32,0.08,0.61,0.12,0.87c0.04,0.22,0.08,0.43,0.15,0.63
	c0.08,0.21,0.19,0.41,0.32,0.61c0.18,0.29,0.38,0.58,0.71,0.89c0.35,0.33,0.84,0.69,1.26,0.92c0.41,0.22,0.76,0.32,1.1,0.41
	c0.29,0.08,0.56,0.15,1.04,0.23c0.39,0.07,0.92,0.13,1.4,0.13'
			/>
			<line x1='274.12' y1='183.65' x2='282.83' y2='183.65' />
			<line x1='274.12' y1='172.59' x2='282.83' y2='172.59' />
			<line x1='206.51' y1='312.45' x2='194.51' y2='312.45' />
			<line x1='197.27' y1='320.93' x2='197.27' y2='320.17' />
			<path d='M196.16,320.17c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='196.16' y1='320.93' x2='196.16' y2='320.17' />
			<path d='M195.97,321.12c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='197.09' y1='319.98' x2='192.86' y2='319.98' />
			<path d='M197.27,320.17c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='197.27' y1='320.93' x2='197.27' y2='320.17' />
			<path d='M197.09,321.12c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='192.86' y1='321.12' x2='197.09' y2='321.12' />
			<path d='M192.86,319.98c-0.08,0-0.14,0.06-0.14,0.14' />
			<line x1='192.72' y1='320.98' x2='192.72' y2='320.12' />
			<path d='M192.72,320.98c0,0.08,0.06,0.14,0.14,0.14' />
			<line x1='194.51' y1='328.65' x2='194.51' y2='321.12' />
			<line x1='206.51' y1='328.65' x2='194.51' y2='328.65' />
			<line x1='206.51' y1='312.45' x2='206.51' y2='328.65' />
			<line x1='194.51' y1='319.98' x2='194.51' y2='312.45' />
			<path
				d='M205.25,338.4c0.67,0,1.06,0.07,1.48,0.14c0.54,0.09,1.12,0.2,1.65,0.4c0.55,0.21,1.04,0.53,1.43,0.86
	c0.3,0.25,0.55,0.5,0.73,0.73c0.15,0.19,0.24,0.36,0.35,0.6c0.1,0.23,0.2,0.51,0.28,0.8c0.08,0.31,0.14,0.62,0.17,0.91
	c0.03,0.3,0.03,0.57,0.03,0.91c0,0.32,0,0.7-0.03,1.04c-0.03,0.32-0.08,0.61-0.12,0.87c-0.04,0.22-0.08,0.43-0.15,0.63
	c-0.08,0.21-0.19,0.41-0.32,0.61c-0.18,0.29-0.38,0.58-0.71,0.89c-0.35,0.33-0.84,0.69-1.26,0.92c-0.41,0.22-0.76,0.32-1.1,0.41
	c-0.29,0.08-0.56,0.15-1.04,0.23c-0.39,0.07-0.92,0.13-1.4,0.13'
			/>
			<line x1='205.25' y1='349.47' x2='196.53' y2='349.47' />
			<line x1='205.25' y1='338.4' x2='196.53' y2='338.4' />
			<polyline points='207.07,173.73 189.12,173.73 189.12,145.7 115.86,145.7 115.86,145.46' />
			<line x1='189.12' y1='173.73' x2='189.12' y2='196.23' />
			<rect x='189.12' y='195.48' width='17.95' height='0.75' />
			<rect x='189.12' y='173.73' width='17.95' height='0.75' />
			<rect x='7.02' y='100.96' width='0.75' height='4.5' />
			<rect x='7.02' y='123.05' width='0.75' height='4.49' />

			<rect
				x='227.63'
				y='230.04'
				transform='matrix(0.5 -0.866 0.866 0.5 -81.4956 320.2639)'
				width='17.96'
				height='1.33'
			/>

			<rect
				x='264.07'
				y='269.98'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -60.6678 80.4757)'
				width='22.46'
				height='1.33'
			/>

			<rect
				x='245.9'
				y='190.33'
				transform='matrix(0.9659 -0.2588 0.2588 0.9659 -40.5989 72.8699)'
				width='21.11'
				height='0.59'
			/>
			<rect x='244.01' y='193.07' width='2.17' height='0.59' />
			<rect x='267.29' y='193.07' width='21.33' height='0.59' />
			<line x1='244.01' y1='211.56' x2='244.01' y2='199.75' />
			<line x1='77.21' y1='30.09' x2='77.21' y2='28.6' />
			<polygon points='81.33,30.09 77.21,30.09 77.21,34.22 80.58,34.22 80.58,33.47 81.33,33.47' />
			<line x1='0.37' y1='126.06' x2='0.37' y2='127.54' />
			<polygon points='193.23,7.16 193.23,11.06 196.6,11.06 196.6,10.31 197.35,10.26 197.12,6.89' />
			<rect x='116.61' y='145.71' width='18' height='0.38' />
			<path d='M117.62,146.08c5.15,1.95,10.84,1.95,15.99,0' />
			<line x1='253.02' y1='235.13' x2='253.02' y2='219.63' />
			<line x1='186.57' y1='123.96' x2='186.57' y2='35.92' />
			<line x1='279.62' y1='137.46' x2='279.62' y2='98.55' />
			<line x1='279.62' y1='107.55' x2='243.99' y2='107.55' />
			<rect x='135.36' y='129.2' width='21' height='15.75' />
			<path d='M146.28,130.33c0-0.23-0.19-0.42-0.42-0.42c-0.23,0-0.42,0.19-0.42,0.42' />
			<path
				d='M146.28,130.84c0.28-0.23,0.32-0.65,0.09-0.93c-0.23-0.28-0.65-0.32-0.93-0.09
	c-0.28,0.23-0.32,0.65-0.09,0.93c0.03,0.03,0.06,0.06,0.09,0.09'
			/>
			<polyline points='146.28,130.33 146.28,130.33 146.28,135.7' />
			<line x1='145.44' y1='135.7' x2='145.44' y2='130.33' />
			<polyline points='147.91,129.67 147.91,131 146.28,131' />
			<line x1='147.91' y1='129.67' x2='145.86' y2='129.67' />
			<path d='M145.44,135.7c0,0.23,0.19,0.42,0.42,0.42c0.23,0,0.42-0.19,0.42-0.42' />
			<rect x='161.63' y='145.7' width='18' height='0.38' />
			<path d='M162.63,146.08c5.15,1.95,10.84,1.95,15.99,0' />
			<line x1='181.88' y1='144.34' x2='181.88' y2='128.83' />
			<line x1='159.38' y1='144.34' x2='159.38' y2='128.83' />
			<circle cx='178.11' cy='143.05' r='0.68' />
			<circle cx='173.14' cy='143.05' r='0.68' />
			<circle cx='163.14' cy='143.05' r='0.68' />
			<circle cx='168.11' cy='143.05' r='0.68' />
			<line x1='159.61' y1='128.6' x2='181.64' y2='128.6' />
			<line x1='181.64' y1='144.58' x2='159.61' y2='144.58' />
			<path d='M181.64,144.58c0.13,0,0.23-0.1,0.23-0.23' />
			<path d='M159.38,144.34c0,0.13,0.1,0.23,0.23,0.23' />
			<path d='M159.61,128.6c-0.13,0-0.23,0.1-0.23,0.23' />
			<path d='M181.88,128.83c0-0.13-0.1-0.23-0.23-0.23' />
		</g>
	);
};

export default Lineworks;

import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Bedroom: IScene = {
	name: 'Bedroom',
	pan: '0deg',
	hotspot: {
		x: 130,
		y: 110,
	},
	images: {
		scheme1: Scheme1.Bedroom,
		scheme2: Scheme2.Bedroom,
	},
	default: {
		latitude: -0.04100517018496763,
		longitude: 0.012659093004313483,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.802376271002875,
				longitude: 4.20171533377044,
			},
			target: {
				name: 'Living',
				view: {
					latitude: 0.005812754107575646,
					longitude: 3.6501108769395425,
				},
			},
		},
	],
};

export default Bedroom;

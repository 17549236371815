import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Living: IScene = {
	name: 'Living',
	pan: '90deg',
	hotspot: {
		x: 90,
		y: 170,
	},
	images: {
		scheme1: Scheme1.Living,
		scheme2: Scheme2.Living,
	},
	default: {
		latitude: -0.002185643030277795,
		longitude: 4.7069607813411665,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.20873721439152138,
				longitude: 1.6132345286096756,
			},
			target: {
				name: 'Kitchen',
				view: {
					latitude: -0.10061994233250449,
					longitude: 1.5698958811452446,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.6519867502676724,
				longitude: 0.16271666768231172,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.05835666042685306,
					longitude: 1.5099429352172944,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.10763854213364277,
				longitude: 1.9818938627629812,
			},
			target: {
				name: 'Main Bath',
				view: {
					latitude: -0.058435866022969574,
					longitude: 4.701653657771356,
				},
			},
		},
	],
};

export default Living;

import SCSS from '../SVG.module.scss';

const index = () => {
	return (
		<g className={SCSS.Flooring}>
			<line x1='263.82' y1='266.64' x2='286.32' y2='266.64' />
			<line x1='210.14' y1='298.62' x2='235.68' y2='298.62' />
			<line x1='242.27' y1='145.03' x2='242.27' y2='170.57' />
			<line x1='6.09' y1='207.56' x2='6.09' y2='130.91' />
			<polyline points='32.65,130.91 32.65,133.36 59.22,133.36' />
			<line x1='83.03' y1='105.39' x2='83.03' y2='56.67' />
			<polyline points='199.13,30.09 199.13,12.57 271.89,7.48' />
		</g>
	);
};

export default index;

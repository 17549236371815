import SVG from '../SVG.module.scss';

const DoorSwings = () => {
	return (
		<g className={SVG.DoorSwings}>
			<path d='M110.62,129.88c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M238.06,193.44c0-13.59-10.64-24.8-24.21-25.51' />
			<path d='M244.01,170.57c13.59,0,24.8-10.64,25.51-24.21' />
			<path d='M261.11,115.6c-4.69-4.23-10.79-6.57-17.11-6.57' />
			<path d='M210.14,300.36c0,13.59,10.64,24.8,24.21,25.51' />
			<path d='M324.74,267.61c-14.41,0.01-26.28,11.3-27.02,25.69' />
			<rect x='7.02' y='105.46' width='0.75' height='17.59' />
			<path d='M33.59,127.54c0-14.39-11.45-26.16-25.84-26.56' />
			<path d='M232.69,238.82c2.44,1.18,5.12,1.79,7.83,1.79' />
			<path d='M263.82,268.38c0,1.52,0.15,3.04,0.46,4.54' />
		</g>
	);
};

export default DoorSwings;

import SCSS from '../SVG.module.scss';

const Dashed = () => {
	return (
		<g className={SCSS.Dashed}>
			<line x1='115.86' y1='136.7' x2='117.36' y2='136.7' />
			<line x1='119.27' y1='136.7' x2='156.54' y2='136.7' />
			<line x1='157.5' y1='136.7' x2='159' y2='136.7' />
			<line x1='134.61' y1='145.71' x2='134.61' y2='144.21' />
			<line x1='134.61' y1='141.83' x2='134.61' y2='131.14' />
			<polyline points='134.61,129.96 134.61,128.46 133.11,128.46' />
			<line x1='131.35' y1='128.46' x2='118.99' y2='128.46' />
			<polyline points='118.11,128.46 116.61,128.46 116.61,129.96' />
			<line x1='116.61' y1='132.33' x2='116.61' y2='143.02' />
			<line x1='116.61' y1='144.21' x2='116.61' y2='145.71' />
			<line x1='159' y1='127.7' x2='159' y2='129.2' />
			<line x1='159' y1='130.95' x2='159' y2='138.83' />
			<polyline points='159,139.7 159,141.2 160.5,141.2' />
			<line x1='162.34' y1='141.2' x2='179.83' y2='141.2' />
			<polyline points='180.75,141.2 182.25,141.2 182.25,139.7' />
			<line x1='182.25' y1='137.95' x2='182.25' y2='130.08' />
			<line x1='182.25' y1='129.2' x2='182.25' y2='127.7' />
			<line x1='182.25' y1='136.7' x2='183.75' y2='136.7' />
			<line x1='185.9' y1='136.7' x2='195.55' y2='136.7' />
			<polyline points='196.62,136.7 198.12,136.7 198.12,138.2' />
			<line x1='198.12' y1='140.33' x2='198.1' y2='171.17' />
			<line x1='198.1' y1='172.23' x2='198.1' y2='173.73' />
			<line x1='254.52' y1='235.13' x2='254.52' y2='233.63' />
			<line x1='254.52' y1='231.55' x2='254.52' y2='222.17' />
			<line x1='254.52' y1='221.13' x2='254.52' y2='219.63' />
			<line x1='188.07' y1='123.96' x2='188.07' y2='122.46' />
			<line x1='188.07' y1='120.51' x2='188.07' y2='38.4' />
			<line x1='188.07' y1='37.42' x2='188.07' y2='35.92' />
			<line x1='281.12' y1='137.46' x2='281.12' y2='135.96' />
			<line x1='281.12' y1='134.02' x2='281.12' y2='101.02' />
			<line x1='281.12' y1='100.05' x2='281.12' y2='98.55' />
			<line x1='279.62' y1='106.05' x2='278.12' y2='106.05' />
			<line x1='276.09' y1='106.05' x2='246.51' y2='106.05' />
			<line x1='245.49' y1='106.05' x2='243.99' y2='106.05' />
		</g>
	);
};

export default Dashed;

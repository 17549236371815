import SCSS from '../SVG.module.scss';

const Furniture = () => {
	return (
		<g className={SCSS.Furniture}>
			<rect x='130.45' y='174.63' width='35.62' height='22.5' />
			<rect x='134.01' y='197.13' width='12' height='6.15' />
			<line x1='146.01' y1='202.3' x2='134.01' y2='202.3' />
			<rect x='150.51' y='197.13' width='12' height='6.15' />
			<line x1='150.51' y1='202.3' x2='162.51' y2='202.3' />
			<rect x='134.01' y='168.48' width='12' height='6.15' />
			<line x1='146.01' y1='169.45' x2='134.01' y2='169.45' />
			<rect x='150.51' y='168.48' width='12' height='6.15' />
			<line x1='150.51' y1='169.45' x2='162.51' y2='169.45' />
			<rect x='49.16' y='165.74' width='22.5' height='22.5' />
			<rect x='29.43' y='199.94' width='65.27' height='24' />
			<polyline points='94.7,202.19 91.7,202.19 91.7,220.94 32.43,220.94 32.43,202.19 29.43,202.19' />
			<line x1='52.41' y1='199.94' x2='52.47' y2='220.94' />
			<line x1='71.73' y1='199.94' x2='71.73' y2='220.94' />
			<rect x='33.1' y='215.83' width='57.93' height='4.5' />
			<rect x='35.59' y='134.95' width='45' height='9' />
			<rect x='40.09' y='138.74' width='36' height='1.12' />
			<polyline points='61.09,139.86 61.09,141.55 55.09,141.55 55.09,139.86' />
			<polyline points='55.09,138.74 55.09,137.05 61.09,137.05 61.09,138.74' />
			<rect x='6.71' y='169.01' width='24' height='25.98' />
			<polyline points='28.46,169.01 28.46,172.01 9.72,172.01 9.72,191.98 28.46,191.98 28.46,194.98' />
			<rect x='10.32' y='172.68' width='4.5' height='18.64' />
			<rect x='8.25' y='202.84' width='18.54' height='18.73' />
			<rect x='287.87' y='30.43' width='0.75' height='45.75' />
			<polyline points='274.02,30.8 228.62,30.8 228.62,75.8 256.69,75.8' />
			<polyline points='270.7,75.8 287.87,75.8 287.87,30.8 280.05,30.8' />
			<rect x='275.34' y='16.22' width='13.29' height='13.29' />
			<rect x='275.34' y='77.1' width='13.29' height='13.29' />
			<polyline points='279.34,34.18 286.45,34.18 286.45,51.8 275.69,51.8' />
			<polyline points='274.6,57.04 274.6,72.43 286.45,72.43 286.45,55.18 274.99,55.18' />
			<polygon points='280.12,30.43 274.17,30.43 256.54,76.18 270.63,76.18' />
			<rect x='102.23' y='36.47' width='45.75' height='0.75' />
			<polyline points='102.6,51.07 102.6,96.47 147.6,96.47 147.6,68.41' />
			<polyline points='147.6,54.39 147.6,37.22 102.6,37.22 102.6,45.05' />
			<rect x='88.02' y='36.47' width='13.29' height='13.29' />
			<rect x='148.89' y='36.47' width='13.29' height='13.29' />
			<polyline points='105.98,45.75 105.98,38.64 123.6,38.64 123.6,49.41' />
			<polyline points='128.84,50.49 144.23,50.49 144.23,38.64 126.98,38.64 126.98,50.11' />
			<polygon points='102.23,44.97 102.23,50.92 147.98,68.55 147.98,54.47' />
		</g>
	);
};

export default Furniture;

import SCSS from '../SVG.module.scss';

const Backdrop = () => {
	return (
		<g id='Backdrop' className={SCSS.Backdrop}>
			<path
				className={SCSS.Interiors}
				id='Interior'
				d='M77.21,30.09h116.02V7.16l98.62-6.9v259.76h37.02v40.08h-87.24v83.71h-54.13v-76.78h18.37
v-81.05H28.46v10.93H0.27V127.54h76.56C76.83,127.54,77.21,29.06,77.21,30.09z'
			/>
			<polygon
				className={SCSS.Balcony}
				id='Balcony'
				points='77.26,14.49 0.25,23.94 0.25,128.46 76.83,128.46'
			/>
		</g>
	);
};

export default Backdrop;

import IScene from './IScene';
import {Scheme1, Scheme2} from '../renderings';
import generateHash from 'random-hash';

const Kitchen: IScene = {
	name: 'Kitchen',
	pan: '90deg',
	hotspot: {
		x: 175,
		y: 160,
	},
	images: {
		scheme1: Scheme1.Kitchen,
		scheme2: Scheme2.Kitchen,
	},
	default: {
		latitude: -0.04167986366982568,
		longitude: 4.725368102636022,
	},
	markers: [
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.274009873543791,
				longitude: 4.514782787755372,
			},
			target: {
				name: 'Living',
				view: {
					latitude: -0.002185643030277795,
					longitude: 4.7069607813411665,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.4103296722848324,
				longitude: 5.033765674813662,
			},
			target: {
				name: 'Bedroom',
				view: {
					latitude: -0.04100517018496763,
					longitude: 0.012659093004313483,
				},
			},
		},
		{
			markerID: generateHash({length: 8}),
			markerPosition: {
				latitude: -0.7729355836829974,
				longitude: 3.0184522408312566,
			},
			target: {
				name: 'Master Bedroom',
				view: {
					latitude: -0.021657523948410695,
					longitude: 1.5696655632740237,
				},
			},
		},
	],
};

export default Kitchen;

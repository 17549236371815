import SCSS from '../SVG.module.scss';

const ThinLineworks = () => {
	return (
		<g className={SCSS.ThinLineworks}>
			<circle cx='224.59' cy='368.59' r='0.75' />
			<line x1='265.27' y1='260.3' x2='285.71' y2='260.3' />
			<rect x='324.74' y='267.61' width='4.13' height='27.02' />
			<line x1='273.49' y1='147.2' x2='284.29' y2='147.2' />
			<path
				d='M282.56,154.17c-0.31-0.34-0.84-0.36-1.18-0.04c-0.34,0.31-0.36,0.84-0.04,1.18
	c0.31,0.34,0.84,0.36,1.18,0.04c0.01-0.01,0.03-0.03,0.04-0.04'
			/>
			<path d='M285.51,153.99c-0.1,0-0.18,0.08-0.19,0.18' />
			<line x1='286.64' y1='153.99' x2='285.51' y2='153.99' />
			<path d='M286.83,154.18c0-0.1-0.08-0.19-0.19-0.19' />
			<line x1='286.83' y1='154.6' x2='286.83' y2='154.18' />
			<line x1='286.83' y1='155.3' x2='286.83' y2='154.88' />
			<path d='M286.64,155.49c0.1,0,0.19-0.08,0.19-0.19' />
			<line x1='285.51' y1='155.49' x2='286.64' y2='155.49' />
			<path d='M285.33,155.31c0,0.1,0.09,0.18,0.19,0.18' />
			<line x1='287.2' y1='154.88' x2='287.2' y2='154.6' />
			<line x1='286.65' y1='154.88' x2='287.2' y2='154.88' />
			<line x1='287.2' y1='154.6' x2='286.65' y2='154.6' />
			<line x1='284.29' y1='162.27' x2='284.29' y2='155.31' />
			<line x1='273.49' y1='162.27' x2='284.29' y2='162.27' />
			<line x1='273.49' y1='147.2' x2='273.49' y2='162.27' />
			<line x1='284.29' y1='154.17' x2='284.29' y2='147.2' />
			<path
				d='M280.21,172.97c0.41,0.03,0.57,0.07,0.71,0.11c0.13,0.04,0.24,0.08,0.35,0.14c0.11,0.06,0.23,0.12,0.32,0.19
	c0.1,0.07,0.18,0.13,0.25,0.2c0.08,0.08,0.15,0.16,0.22,0.26c0.08,0.12,0.17,0.26,0.24,0.4c0.08,0.16,0.14,0.32,0.19,0.47
	c0.05,0.16,0.09,0.31,0.12,0.47c0.04,0.18,0.07,0.38,0.09,0.57c0.03,0.24,0.05,0.47,0.06,0.71c0.02,0.31,0.03,0.62,0.03,0.97
	c0.01,0.43,0.01,0.91-0.01,1.41c-0.01,0.47-0.04,0.95-0.08,1.34c-0.03,0.32-0.07,0.56-0.14,0.85c-0.05,0.19-0.11,0.4-0.18,0.57
	c-0.05,0.15-0.11,0.27-0.17,0.39c-0.06,0.11-0.13,0.21-0.23,0.33c-0.1,0.12-0.23,0.25-0.36,0.35c-0.12,0.1-0.24,0.18-0.35,0.24
	c-0.09,0.06-0.18,0.11-0.28,0.15c-0.09,0.04-0.19,0.07-0.3,0.09c-0.1,0.02-0.19,0.04-0.31,0.06c-0.12,0.02-0.27,0.04-0.65,0.04'
			/>
			<path
				d='M274.12,172.97c-0.3,0-0.53,0.01-0.74,0.04c-0.1,0.01-0.2,0.02-0.3,0.04c-0.09,0.01-0.19,0.03-0.28,0.05
	c-0.1,0.02-0.19,0.03-0.29,0.05c-0.1,0.02-0.2,0.04-0.29,0.05c-0.1,0.02-0.2,0.04-0.29,0.06c-0.1,0.02-0.19,0.04-0.29,0.07
	s-0.19,0.05-0.28,0.08c-0.09,0.03-0.18,0.06-0.27,0.09c-0.09,0.03-0.17,0.07-0.25,0.11c-0.08,0.04-0.16,0.08-0.24,0.12
	c-0.16,0.09-0.31,0.18-0.45,0.28c-0.14,0.1-0.28,0.21-0.4,0.31c-0.13,0.11-0.24,0.21-0.34,0.31c-0.1,0.1-0.19,0.2-0.26,0.29
	c-0.04,0.05-0.07,0.09-0.1,0.13c-0.03,0.04-0.06,0.09-0.08,0.13c-0.03,0.05-0.05,0.09-0.08,0.15c-0.03,0.05-0.05,0.11-0.08,0.18
	s-0.06,0.14-0.08,0.21c-0.03,0.07-0.05,0.15-0.08,0.23s-0.05,0.16-0.07,0.24c-0.02,0.08-0.04,0.16-0.06,0.24
	c-0.04,0.16-0.06,0.32-0.08,0.47c-0.01,0.08-0.02,0.15-0.02,0.22c-0.01,0.07-0.01,0.14-0.01,0.22c-0.01,0.14-0.01,0.29-0.01,0.46
	c0,0.17,0,0.35,0,0.54c0,0.19,0.01,0.38,0.02,0.55c0.01,0.09,0.01,0.17,0.02,0.25s0.02,0.16,0.03,0.24
	c0.01,0.08,0.02,0.15,0.04,0.23c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.04,0.01,0.07,0.02,0.11c0.01,0.07,0.03,0.14,0.04,0.2
	c0.01,0.06,0.03,0.12,0.04,0.18c0.01,0.03,0.02,0.05,0.02,0.08c0.01,0.03,0.02,0.05,0.03,0.08c0.02,0.05,0.04,0.1,0.07,0.16
	c0.03,0.05,0.06,0.11,0.09,0.17c0.03,0.06,0.07,0.12,0.11,0.17c0.04,0.06,0.07,0.12,0.11,0.18c0.04,0.06,0.08,0.12,0.12,0.17
	c0.04,0.06,0.09,0.11,0.14,0.17c0.05,0.06,0.1,0.12,0.16,0.18s0.12,0.12,0.19,0.18c0.07,0.06,0.14,0.12,0.22,0.19
	c0.15,0.12,0.32,0.25,0.49,0.36c0.08,0.06,0.17,0.11,0.25,0.16c0.08,0.05,0.16,0.09,0.23,0.13c0.08,0.04,0.15,0.07,0.22,0.1
	c0.07,0.03,0.14,0.06,0.21,0.08c0.07,0.02,0.14,0.05,0.2,0.07c0.07,0.02,0.14,0.04,0.2,0.06c0.07,0.02,0.14,0.04,0.21,0.06
	c0.07,0.02,0.14,0.04,0.21,0.05c0.07,0.02,0.15,0.04,0.23,0.05c0.08,0.02,0.17,0.04,0.27,0.05c0.05,0.01,0.1,0.02,0.16,0.03
	s0.11,0.02,0.17,0.03c0.12,0.02,0.25,0.04,0.39,0.05c0.14,0.02,0.28,0.03,0.42,0.04c0.14,0.01,0.28,0.02,0.41,0.02'
			/>
			<line x1='274.12' y1='172.97' x2='280.21' y2='172.97' />
			<line x1='274.12' y1='183.28' x2='279.74' y2='183.28' />
			<line x1='205.88' y1='313.02' x2='195.08' y2='313.02' />
			<path
				d='M196.81,321.12c0.31,0.34,0.84,0.36,1.18,0.04c0.34-0.31,0.36-0.84,0.04-1.18s-0.84-0.36-1.18-0.04
	c-0.01,0.01-0.03,0.03-0.04,0.04'
			/>
			<path d='M194.04,319.98c0-0.1-0.09-0.18-0.19-0.18' />
			<line x1='192.73' y1='319.8' x2='193.85' y2='319.8' />
			<path d='M192.73,319.8c-0.1,0-0.19,0.08-0.19,0.19' />
			<line x1='192.54' y1='320.41' x2='192.54' y2='319.99' />
			<line x1='192.54' y1='321.11' x2='192.54' y2='320.69' />
			<path d='M192.54,321.11c0,0.1,0.08,0.19,0.19,0.19' />
			<line x1='193.85' y1='321.3' x2='192.73' y2='321.3' />
			<path d='M193.85,321.3c0.1,0,0.18-0.08,0.19-0.18' />
			<line x1='192.16' y1='320.69' x2='192.16' y2='320.41' />
			<line x1='192.72' y1='320.69' x2='192.16' y2='320.69' />
			<line x1='192.16' y1='320.41' x2='192.72' y2='320.41' />
			<line x1='195.08' y1='328.09' x2='195.08' y2='321.12' />
			<line x1='205.88' y1='328.09' x2='195.08' y2='328.09' />
			<line x1='205.88' y1='313.02' x2='205.88' y2='328.09' />
			<line x1='195.08' y1='319.98' x2='195.08' y2='313.02' />
			<path
				d='M199.15,338.78c-0.41,0.03-0.57,0.07-0.71,0.11c-0.13,0.04-0.24,0.08-0.35,0.14
	c-0.11,0.06-0.23,0.12-0.32,0.19c-0.1,0.07-0.18,0.13-0.25,0.2c-0.08,0.08-0.15,0.16-0.22,0.26c-0.08,0.12-0.17,0.26-0.24,0.4
	c-0.08,0.16-0.14,0.32-0.19,0.47c-0.05,0.16-0.09,0.31-0.12,0.47c-0.04,0.18-0.07,0.38-0.09,0.57c-0.03,0.24-0.05,0.47-0.06,0.71
	c-0.02,0.31-0.03,0.62-0.03,0.97c-0.01,0.43-0.01,0.91,0.01,1.41c0.01,0.47,0.04,0.95,0.08,1.34c0.03,0.32,0.07,0.56,0.14,0.85
	c0.05,0.19,0.11,0.4,0.18,0.57c0.05,0.15,0.11,0.27,0.17,0.39c0.06,0.11,0.13,0.21,0.23,0.33c0.1,0.12,0.23,0.25,0.36,0.35
	c0.12,0.1,0.24,0.18,0.35,0.24c0.09,0.06,0.18,0.11,0.28,0.15c0.09,0.04,0.19,0.07,0.3,0.09c0.1,0.02,0.19,0.04,0.31,0.06
	c0.12,0.02,0.27,0.04,0.65,0.04'
			/>
			<path
				d='M205.25,338.78c0.3,0,0.53,0.01,0.74,0.04c0.1,0.01,0.2,0.02,0.3,0.04s0.19,0.03,0.28,0.05
	s0.19,0.03,0.29,0.05c0.1,0.02,0.2,0.04,0.29,0.05c0.1,0.02,0.2,0.04,0.29,0.06c0.1,0.02,0.19,0.04,0.29,0.07
	c0.09,0.02,0.19,0.05,0.28,0.08s0.18,0.06,0.27,0.09c0.09,0.03,0.17,0.07,0.25,0.11c0.08,0.04,0.16,0.08,0.24,0.12
	c0.16,0.09,0.31,0.18,0.45,0.28c0.14,0.1,0.28,0.21,0.4,0.31c0.13,0.11,0.24,0.21,0.34,0.31c0.1,0.1,0.19,0.2,0.26,0.29
	c0.04,0.05,0.07,0.09,0.1,0.13s0.06,0.09,0.08,0.13c0.03,0.05,0.05,0.09,0.08,0.15c0.03,0.05,0.05,0.11,0.08,0.18
	c0.03,0.07,0.06,0.14,0.08,0.21c0.03,0.07,0.05,0.15,0.08,0.23s0.05,0.16,0.07,0.24c0.02,0.08,0.04,0.16,0.06,0.24
	c0.04,0.16,0.06,0.32,0.08,0.47c0.01,0.08,0.02,0.15,0.02,0.22c0.01,0.07,0.01,0.14,0.01,0.22c0.01,0.14,0.01,0.29,0.01,0.46
	c0,0.17,0,0.35,0,0.54c0,0.19-0.01,0.38-0.02,0.55c-0.01,0.09-0.01,0.17-0.02,0.25s-0.02,0.16-0.03,0.24
	c-0.01,0.08-0.02,0.15-0.04,0.23c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.04-0.01,0.07-0.02,0.11c-0.01,0.07-0.03,0.14-0.04,0.2
	c-0.01,0.06-0.03,0.12-0.04,0.18c-0.01,0.03-0.02,0.05-0.02,0.08s-0.02,0.05-0.03,0.08c-0.02,0.05-0.04,0.1-0.07,0.16
	c-0.03,0.05-0.06,0.11-0.09,0.17s-0.07,0.12-0.11,0.17c-0.04,0.06-0.07,0.12-0.11,0.18c-0.04,0.06-0.08,0.12-0.12,0.17
	c-0.04,0.06-0.09,0.11-0.14,0.17c-0.05,0.06-0.1,0.12-0.16,0.18c-0.06,0.06-0.12,0.12-0.19,0.18c-0.07,0.06-0.14,0.12-0.22,0.19
	c-0.15,0.12-0.32,0.25-0.49,0.36c-0.08,0.06-0.17,0.11-0.25,0.16s-0.16,0.09-0.23,0.13c-0.08,0.04-0.15,0.07-0.22,0.1
	c-0.07,0.03-0.14,0.06-0.21,0.08s-0.14,0.05-0.2,0.07c-0.07,0.02-0.14,0.04-0.2,0.06c-0.07,0.02-0.14,0.04-0.21,0.06
	c-0.07,0.02-0.14,0.04-0.21,0.05c-0.07,0.02-0.15,0.04-0.23,0.05c-0.08,0.02-0.17,0.04-0.27,0.05c-0.05,0.01-0.1,0.02-0.16,0.03
	c-0.05,0.01-0.11,0.02-0.17,0.03c-0.12,0.02-0.25,0.04-0.39,0.05s-0.28,0.03-0.42,0.04c-0.14,0.01-0.28,0.02-0.41,0.02'
			/>
			<line x1='205.25' y1='338.78' x2='199.15' y2='338.78' />
			<line x1='205.25' y1='349.09' x2='199.62' y2='349.09' />
			<line x1='191' y1='195.48' x2='191' y2='174.48' />
			<line x1='7.02' y1='130.91' x2='33.59' y2='130.91' />
			<line x1='7.02' y1='127.54' x2='33.59' y2='127.54' />
			<rect x='244.34' y='193.66' width='43.92' height='20.34' />
			<line x1='288.26' y1='193.66' x2='274.35' y2='203.37' />
			<line x1='274.37' y1='205.04' x2='288.26' y2='214' />
			<line x1='271.75' y1='204.7' x2='244.34' y2='214' />
			<line x1='244.34' y1='193.66' x2='271.75' y2='203.72' />
			<circle cx='273.14' cy='204.22' r='1.48' />
			<line x1='243.54' y1='190.71' x2='254.99' y2='190.71' />
			<line x1='257.28' y1='190.71' x2='282.83' y2='190.71' />
			<path d='M119.38,146.08c4.07,1.2,8.39,1.2,12.46,0' />
			<circle cx='147.64' cy='130.33' r='0.19' />
			<line x1='147.07' y1='129.67' x2='147.07' y2='131' />
			<polyline points='145.44,132.2 136.86,132.2 136.86,143.45 154.86,143.45 154.86,132.2 146.28,132.2' />
			<path
				d='M145.58,136.01c-0.38,0.16-0.56,0.6-0.41,0.98c0.16,0.38,0.6,0.56,0.98,0.41c0.38-0.16,0.56-0.6,0.41-0.98
	c-0.08-0.18-0.22-0.33-0.41-0.41'
			/>
			<path d='M164.39,146.08c4.07,1.2,8.39,1.2,12.46,0' />
			<path
				d='M178.31,143.45c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6
	c0.04,0.09,0.11,0.16,0.2,0.2'
			/>
			<line x1='178.31' y1='143.7' x2='178.31' y2='142.65' />
			<line x1='177.91' y1='143.7' x2='177.91' y2='142.65' />
			<path
				d='M173.34,143.45c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6
	c0.04,0.09,0.11,0.16,0.2,0.2'
			/>
			<line x1='173.34' y1='143.7' x2='173.34' y2='142.65' />
			<line x1='172.94' y1='143.7' x2='172.94' y2='142.65' />
			<path
				d='M163.34,143.45c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6
	c0.04,0.09,0.11,0.16,0.2,0.2'
			/>
			<line x1='162.94' y1='143.7' x2='162.94' y2='142.65' />
			<line x1='163.34' y1='143.7' x2='163.34' y2='142.65' />
			<path
				d='M168.32,143.45c0.22-0.11,0.31-0.38,0.2-0.6c-0.11-0.22-0.38-0.31-0.6-0.2c-0.22,0.11-0.31,0.38-0.2,0.6
	c0.04,0.09,0.11,0.16,0.2,0.2'
			/>
			<line x1='167.91' y1='143.7' x2='167.91' y2='142.65' />
			<line x1='168.32' y1='143.7' x2='168.32' y2='142.65' />
			<line x1='159.38' y1='141.33' x2='181.88' y2='141.33' />
			<line x1='159.38' y1='140.81' x2='164.69' y2='140.81' />
			<line x1='164.93' y1='140.81' x2='181.88' y2='140.81' />
			<line x1='159.38' y1='129.12' x2='164.69' y2='129.12' />
			<line x1='164.93' y1='129.12' x2='176.32' y2='129.12' />
			<line x1='176.56' y1='129.12' x2='181.88' y2='129.12' />
			<line x1='159.38' y1='140.24' x2='164.69' y2='140.24' />
			<line x1='164.93' y1='140.24' x2='176.32' y2='140.24' />
			<line x1='176.56' y1='140.24' x2='181.88' y2='140.24' />
			<line x1='159.38' y1='139.95' x2='164.69' y2='139.95' />
			<line x1='164.93' y1='139.95' x2='176.32' y2='139.95' />
			<line x1='176.56' y1='139.95' x2='181.88' y2='139.95' />
			<line x1='166.19' y1='130.25' x2='175.06' y2='130.25' />
			<line x1='177.82' y1='130.25' x2='181.88' y2='130.25' />
			<line x1='163.43' y1='130.25' x2='159.38' y2='130.25' />
			<line x1='166.18' y1='129.97' x2='175.07' y2='129.97' />
			<line x1='177.81' y1='129.97' x2='181.88' y2='129.97' />
			<line x1='163.44' y1='129.97' x2='159.38' y2='129.97' />
			<line x1='166.29' y1='131.69' x2='174.96' y2='131.69' />
			<line x1='177.92' y1='131.69' x2='181.88' y2='131.69' />
			<line x1='163.33' y1='131.69' x2='159.38' y2='131.69' />
			<line x1='166.29' y1='131.4' x2='174.96' y2='131.4' />
			<line x1='177.92' y1='131.4' x2='181.88' y2='131.4' />
			<line x1='163.33' y1='131.4' x2='159.38' y2='131.4' />
			<line x1='166.2' y1='132.83' x2='175.05' y2='132.83' />
			<line x1='177.83' y1='132.83' x2='181.88' y2='132.83' />
			<line x1='163.42' y1='132.83' x2='159.38' y2='132.83' />
			<line x1='166.19' y1='133.12' x2='175.06' y2='133.12' />
			<line x1='177.82' y1='133.12' x2='181.88' y2='133.12' />
			<line x1='163.43' y1='133.12' x2='159.38' y2='133.12' />
			<line x1='166.19' y1='136' x2='175.06' y2='136' />
			<line x1='177.82' y1='136' x2='181.88' y2='136' />
			<line x1='163.43' y1='136' x2='159.38' y2='136' />
			<line x1='166.18' y1='135.71' x2='175.07' y2='135.71' />
			<line x1='177.81' y1='135.71' x2='181.88' y2='135.71' />
			<line x1='163.44' y1='135.71' x2='159.38' y2='135.71' />
			<line x1='166.29' y1='137.43' x2='174.96' y2='137.43' />
			<line x1='177.92' y1='137.43' x2='181.88' y2='137.43' />
			<line x1='163.33' y1='137.43' x2='159.38' y2='137.43' />
			<line x1='166.29' y1='137.14' x2='174.96' y2='137.14' />
			<line x1='177.92' y1='137.14' x2='181.88' y2='137.14' />
			<line x1='163.33' y1='137.14' x2='159.38' y2='137.14' />
			<line x1='166.2' y1='138.57' x2='175.05' y2='138.57' />
			<line x1='177.83' y1='138.57' x2='181.88' y2='138.57' />
			<line x1='163.42' y1='138.57' x2='159.38' y2='138.57' />
			<line x1='166.19' y1='138.86' x2='175.06' y2='138.86' />
			<line x1='177.82' y1='138.86' x2='181.88' y2='138.86' />
			<line x1='163.43' y1='138.86' x2='159.38' y2='138.86' />
			<line x1='159.38' y1='134.33' x2='164.69' y2='134.33' />
			<line x1='164.93' y1='134.33' x2='176.32' y2='134.33' />
			<line x1='176.56' y1='134.33' x2='181.88' y2='134.33' />
			<line x1='159.38' y1='134.62' x2='164.69' y2='134.62' />
			<line x1='164.93' y1='134.62' x2='176.32' y2='134.62' />
			<line x1='176.56' y1='134.62' x2='181.88' y2='134.62' />
			<line x1='170.63' y1='141.33' x2='170.63' y2='128.6' />
			<line x1='170.2' y1='140.81' x2='170.2' y2='140.24' />
			<line x1='170.2' y1='139.95' x2='170.2' y2='138.86' />
			<line x1='170.2' y1='138.57' x2='170.2' y2='137.43' />
			<line x1='170.2' y1='137.14' x2='170.2' y2='136' />
			<line x1='170.2' y1='135.71' x2='170.2' y2='134.62' />
			<line x1='170.2' y1='134.33' x2='170.2' y2='133.12' />
			<line x1='170.2' y1='132.83' x2='170.2' y2='131.69' />
			<line x1='170.2' y1='131.4' x2='170.2' y2='130.25' />
			<line x1='170.2' y1='129.97' x2='170.2' y2='129.12' />
			<line x1='171.05' y1='140.81' x2='171.05' y2='140.24' />
			<line x1='171.05' y1='139.95' x2='171.05' y2='138.86' />
			<line x1='171.05' y1='138.57' x2='171.05' y2='137.43' />
			<line x1='171.05' y1='137.14' x2='171.05' y2='136' />
			<line x1='171.05' y1='135.71' x2='171.05' y2='134.62' />
			<line x1='171.05' y1='134.33' x2='171.05' y2='133.12' />
			<line x1='171.05' y1='132.83' x2='171.05' y2='131.69' />
			<line x1='171.05' y1='131.4' x2='171.05' y2='130.25' />
			<line x1='171.05' y1='129.97' x2='171.05' y2='129.12' />
			<line x1='159.81' y1='129.12' x2='159.81' y2='129.97' />
			<line x1='159.81' y1='130.25' x2='159.81' y2='131.4' />
			<line x1='159.81' y1='131.69' x2='159.81' y2='132.83' />
			<line x1='159.81' y1='133.12' x2='159.81' y2='134.33' />
			<line x1='159.81' y1='134.62' x2='159.81' y2='135.71' />
			<line x1='159.81' y1='136' x2='159.81' y2='137.14' />
			<line x1='159.81' y1='137.43' x2='159.81' y2='138.57' />
			<line x1='159.81' y1='138.86' x2='159.81' y2='139.95' />
			<line x1='159.81' y1='140.24' x2='159.81' y2='140.81' />
			<line x1='181.44' y1='140.81' x2='181.44' y2='140.24' />
			<line x1='181.44' y1='139.95' x2='181.44' y2='138.86' />
			<line x1='181.44' y1='138.57' x2='181.44' y2='137.43' />
			<line x1='181.44' y1='137.14' x2='181.44' y2='136' />
			<line x1='181.44' y1='135.71' x2='181.44' y2='134.62' />
			<line x1='181.44' y1='134.33' x2='181.44' y2='133.12' />
			<line x1='181.44' y1='132.83' x2='181.44' y2='131.69' />
			<line x1='181.44' y1='131.4' x2='181.44' y2='130.25' />
			<line x1='181.44' y1='129.97' x2='181.44' y2='129.12' />
			<line x1='176.32' y1='129.12' x2='176.32' y2='130.06' />
			<line x1='176.32' y1='133.02' x2='176.32' y2='134.33' />
			<line x1='176.32' y1='134.62' x2='176.32' y2='135.81' />
			<line x1='176.32' y1='138.77' x2='176.32' y2='139.95' />
			<line x1='176.32' y1='140.24' x2='176.32' y2='140.81' />
			<line x1='176.56' y1='129.12' x2='176.56' y2='130.06' />
			<line x1='176.56' y1='133.02' x2='176.56' y2='134.33' />
			<line x1='176.56' y1='134.62' x2='176.56' y2='135.81' />
			<line x1='176.56' y1='138.77' x2='176.56' y2='139.95' />
			<line x1='176.56' y1='140.24' x2='176.56' y2='140.81' />
			<line x1='164.93' y1='138.77' x2='164.93' y2='139.95' />
			<line x1='164.93' y1='140.24' x2='164.93' y2='140.81' />
			<line x1='164.93' y1='135.81' x2='164.93' y2='134.62' />
			<line x1='164.93' y1='134.33' x2='164.93' y2='133.02' />
			<line x1='164.93' y1='130.06' x2='164.93' y2='129.12' />
			<line x1='164.69' y1='138.77' x2='164.69' y2='139.95' />
			<line x1='164.69' y1='140.24' x2='164.69' y2='140.81' />
			<line x1='164.69' y1='135.81' x2='164.69' y2='134.62' />
			<line x1='164.69' y1='134.33' x2='164.69' y2='133.02' />
			<line x1='164.69' y1='130.06' x2='164.69' y2='129.12' />
			<circle cx='176.44' cy='131.54' r='0.86' />
			<path d='M177.2,130.6c-0.44-0.36-1.08-0.36-1.52,0' />
			<path d='M177.35,132.33c0.4-0.46,0.39-1.14-0.01-1.59' />
			<path d='M175.67,132.48c0.45,0.36,1.09,0.36,1.53,0' />
			<path d='M175.53,130.74c-0.4,0.45-0.4,1.13-0.01,1.59' />
			<circle cx='164.81' cy='131.54' r='0.75' />
			<path d='M163.94,130.78c-0.38,0.43-0.38,1.08-0.01,1.52' />
			<path d='M165.54,130.64c-0.42-0.34-1.02-0.34-1.45,0' />
			<path d='M165.69,132.3c0.38-0.44,0.37-1.08-0.01-1.52' />
			<path d='M164.08,132.44c0.43,0.35,1.03,0.35,1.46,0' />
			<path d='M163.9,138.37c0.52,0.44,1.29,0.44,1.81,0' />
			<path d='M163.76,136.34c-0.48,0.53-0.48,1.35-0.01,1.88' />
			<path d='M165.71,136.2c-0.52-0.43-1.28-0.43-1.8,0' />
			<path d='M165.86,138.23c0.48-0.54,0.48-1.35-0.01-1.88' />
			<path d='M163.63,138.65c0.3,0.26,0.67,0.41,1.06,0.44' />
			<path d='M163.01,137.43c0.03,0.4,0.2,0.78,0.47,1.08' />
			<path d='M163.49,136.06c-0.28,0.3-0.45,0.68-0.48,1.09' />
			<path d='M164.69,135.49c-0.39,0.03-0.76,0.18-1.06,0.43' />
			<path d='M165.99,135.92c-0.3-0.25-0.67-0.41-1.06-0.43' />
			<path d='M166.61,137.14c-0.03-0.41-0.2-0.79-0.48-1.09' />
			<path d='M166.14,138.51c0.27-0.3,0.44-0.68,0.47-1.08' />
			<path d='M164.93,139.09c0.39-0.03,0.76-0.18,1.06-0.44' />
			<path d='M177.42,136.12c-0.24-0.2-0.54-0.33-0.86-0.35' />
			<path d='M177.96,137.14c-0.03-0.33-0.17-0.64-0.39-0.88' />
			<path d='M177.57,138.31c0.22-0.24,0.35-0.55,0.39-0.88' />
			<path d='M176.56,138.81c0.32-0.03,0.62-0.15,0.87-0.36' />
			<path d='M175.45,138.45c0.24,0.21,0.55,0.33,0.87,0.36' />
			<path d='M174.92,137.43c0.03,0.33,0.17,0.63,0.39,0.88' />
			<path d='M175.31,136.26c-0.22,0.24-0.36,0.55-0.39,0.88' />
			<path d='M176.32,135.77c-0.32,0.02-0.62,0.15-0.86,0.35' />
			<path d='M177.64,135.89c-0.3-0.26-0.69-0.42-1.09-0.44' />
			<path d='M178.28,137.14c-0.03-0.42-0.21-0.81-0.49-1.12' />
			<path d='M177.8,138.54c0.28-0.31,0.45-0.7,0.49-1.11' />
			<path d='M176.56,139.13c0.4-0.03,0.79-0.18,1.09-0.45' />
			<path d='M175.23,138.68c0.31,0.26,0.69,0.42,1.09,0.45' />
			<path d='M174.6,137.43c0.03,0.41,0.2,0.81,0.49,1.11' />
			<path d='M175.09,136.03c-0.29,0.31-0.46,0.7-0.49,1.12' />
			<path d='M176.32,135.44c-0.4,0.03-0.78,0.18-1.09,0.44' />
			<line x1='164.16' y1='138.11' x2='163.43' y2='138.86' />
			<line x1='165.61' y1='136.6' x2='166.19' y2='136' />
			<line x1='164.01' y1='137.96' x2='163.42' y2='138.57' />
			<line x1='165.46' y1='136.46' x2='166.18' y2='135.71' />
			<line x1='165.47' y1='138.11' x2='166.19' y2='138.86' />
			<line x1='164.02' y1='136.6' x2='163.43' y2='136' />
			<line x1='165.61' y1='137.96' x2='166.2' y2='138.57' />
			<line x1='164.16' y1='136.46' x2='163.44' y2='135.71' />
			<line x1='166.29' y1='137.14' x2='166.29' y2='137.43' />
			<line x1='163.33' y1='137.14' x2='163.33' y2='137.43' />
			<line x1='165.61' y1='136.6' x2='165.46' y2='136.46' />
			<line x1='164.02' y1='136.6' x2='164.16' y2='136.46' />
			<line x1='165.61' y1='137.96' x2='165.47' y2='138.11' />
			<line x1='164.01' y1='137.96' x2='164.16' y2='138.11' />
			<line x1='164.93' y1='135.81' x2='164.69' y2='135.81' />
			<line x1='164.93' y1='138.77' x2='164.69' y2='138.77' />
			<line x1='164.16' y1='132.36' x2='163.43' y2='133.12' />
			<line x1='165.61' y1='130.86' x2='166.19' y2='130.25' />
			<line x1='164.01' y1='132.22' x2='163.42' y2='132.83' />
			<line x1='165.46' y1='130.72' x2='166.18' y2='129.97' />
			<line x1='165.47' y1='132.36' x2='166.19' y2='133.12' />
			<line x1='164.02' y1='130.86' x2='163.43' y2='130.25' />
			<line x1='165.61' y1='132.22' x2='166.2' y2='132.83' />
			<line x1='164.16' y1='130.72' x2='163.44' y2='129.97' />
			<line x1='166.29' y1='131.4' x2='166.29' y2='131.69' />
			<line x1='163.33' y1='131.4' x2='163.33' y2='131.69' />
			<line x1='165.61' y1='130.86' x2='165.46' y2='130.72' />
			<line x1='164.02' y1='130.86' x2='164.16' y2='130.72' />
			<line x1='165.61' y1='132.22' x2='165.47' y2='132.36' />
			<line x1='164.01' y1='132.22' x2='164.16' y2='132.36' />
			<line x1='164.93' y1='130.06' x2='164.69' y2='130.06' />
			<line x1='164.93' y1='133.02' x2='164.69' y2='133.02' />
			<line x1='175.78' y1='132.36' x2='175.06' y2='133.12' />
			<line x1='177.23' y1='130.86' x2='177.82' y2='130.25' />
			<line x1='175.64' y1='132.22' x2='175.05' y2='132.83' />
			<line x1='177.09' y1='130.72' x2='177.81' y2='129.97' />
			<line x1='177.09' y1='132.36' x2='177.82' y2='133.12' />
			<line x1='175.64' y1='130.86' x2='175.06' y2='130.25' />
			<line x1='177.24' y1='132.22' x2='177.83' y2='132.83' />
			<line x1='175.79' y1='130.72' x2='175.07' y2='129.97' />
			<line x1='177.92' y1='131.4' x2='177.92' y2='131.69' />
			<line x1='174.96' y1='131.4' x2='174.96' y2='131.69' />
			<line x1='177.23' y1='130.86' x2='177.09' y2='130.72' />
			<line x1='175.64' y1='130.86' x2='175.79' y2='130.72' />
			<line x1='177.24' y1='132.22' x2='177.09' y2='132.36' />
			<line x1='175.64' y1='132.22' x2='175.78' y2='132.36' />
			<line x1='176.56' y1='130.06' x2='176.32' y2='130.06' />
			<line x1='176.56' y1='133.02' x2='176.32' y2='133.02' />
			<line x1='175.78' y1='138.11' x2='175.06' y2='138.86' />
			<line x1='177.23' y1='136.6' x2='177.82' y2='136' />
			<line x1='175.64' y1='137.96' x2='175.05' y2='138.57' />
			<line x1='177.09' y1='136.46' x2='177.81' y2='135.71' />
			<line x1='177.09' y1='138.11' x2='177.82' y2='138.86' />
			<line x1='175.64' y1='136.6' x2='175.06' y2='136' />
			<line x1='177.24' y1='137.96' x2='177.83' y2='138.57' />
			<line x1='175.79' y1='136.46' x2='175.07' y2='135.71' />
			<line x1='177.92' y1='137.14' x2='177.92' y2='137.43' />
			<line x1='174.96' y1='137.14' x2='174.96' y2='137.43' />
			<line x1='177.23' y1='136.6' x2='177.09' y2='136.46' />
			<line x1='175.64' y1='136.6' x2='175.79' y2='136.46' />
			<line x1='177.24' y1='137.96' x2='177.09' y2='138.11' />
			<line x1='175.64' y1='137.96' x2='175.78' y2='138.11' />
			<line x1='176.56' y1='135.81' x2='176.32' y2='135.81' />
			<line x1='176.56' y1='138.77' x2='176.32' y2='138.77' />
		</g>
	);
};

export default ThinLineworks;
